import { CaptureEvent, CaptureEventIds, CaptureEventTypes } from './captureEvents';
import { DeviceInfo } from './deviceInfo';

export class JsonRpc {
  jsonrpc: string = '2.0';
  id: number = 0;
  constructor(id: number) {
    this.id = id;
  }
};

// {
//     "jsonrpc": "2.0",
//     "result": {
//         "handle": 0,
//         "event": {
//             "id": 1,
//             "type": 6,
//             "value": {
//                 "guid": "{2EAAAA3F-B51E-4537-851C-31CA683C3BEF}",
//                 "name": "Socket D740 [E537BA]",
//                 "type": 196619
//             }
//         }
//     }
// }

export class CaptureEventResult<T> {
  handle?: number;
  event: CaptureEvent<T>;
  constructor(id: CaptureEventIds, type: CaptureEventTypes, value: T, handle?: number) {
    this.event = new CaptureEvent<T>(id, type, value);
    if(handle) {
      this.handle = handle;
    }
  }
}

export class JRpcEvent<T> {
  jsonrpc: string = '2.0';
  result: CaptureEventResult<T>;
  constructor(id: CaptureEventIds, type: CaptureEventTypes, value: T, handle?: number) {
    this.result = new CaptureEventResult<T>(id, type, value, handle);
  }
};

export class JRpcEventDevicePresence extends JRpcEvent<DeviceInfo> {
  constructor(id: CaptureEventIds, deviceInfo: DeviceInfo, handle?: number) {
    super(id, CaptureEventTypes.DeviceInfo, deviceInfo, handle);
  }
};

export class JRpcRequest<T> extends JsonRpc {
  method: string;
  params?: T;
  constructor(id: number, method: string, params: T) {
    super(id);
    this.method = method;
    if(params && params !== null) {
      this.params = params;
    }
  }
};

export class JRpcResponse<T> extends JsonRpc {
  result?: T;
  constructor(id: number, response: T) {
    super(id);
    this.result = response;
  }
};

export class JRpcError extends JsonRpc {
  error: {
    code: number,
    message?: string
  }
  constructor(id: number, code: number, message?: string) {
    super(id);
    this.error = { code, message };
  }
};
