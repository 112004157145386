// RPC Transport
//
// Interface for a transport layer used by CaptureJS
//
//  (c) 2019 Socket Mobile, Inc. All rights reserved

import { JRpcRequest, JRpcResponse, JRpcEvent, JRpcError } from './jsonRpc'
export class BaseTransport {
  handles:{handle:number}[];
  constructor() {
    this.handles = [];
  }
  generateHandle(): number {
    let newHandle: number;
    while(1) {
      newHandle = Math.floor(Math.random()*100) + 1;
      if(this.handles.length === 0) {
        this.handles.push({handle: newHandle});
        return newHandle;
      }
      if (this.handles.every(h => h.handle !== newHandle)) {
        this.handles.push({handle: newHandle});
        return newHandle;
      }
    }
    return 0;
  }

}

export interface RpcTransport extends BaseTransport {
  open(host: string, notification:(event: JRpcEvent<any>)=>void): Promise<{handle: number}>;
  close(handle: number): Promise<number>;
  send<T>(handle: number, jsonRpc: JRpcRequest<T>): Promise<JRpcResponse<any>> | Promise<JRpcError>;
}
