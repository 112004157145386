// capture.ts
// main entry point for Socket Mobile CaptureJS SDK
//
// Copyright © 2019 Socket Mobile, Inc.
import AppInfo from './appInfo';
import CaptureProperty from './captureProperty';
import { CapturePropertyTypes } from './gen/propertyIdsTypes';
import { RpcTransport } from './rpcTransport';
import Transport from './transport';
import CaptureOptions from './captureOptions';
import { JRpcRequest, JRpcEvent, JRpcError, CaptureEventResult } from './jsonRpc';
import { CaptureEvent } from './captureEvents';

import SktErrors from './gen/errors';
import { Logger } from './logger';

const ERRMSG_NO_TRANSPORT = 'no transport, is this initialized?';

const DEFAULT_HOST = "http://127.0.0.1:18481";
type Notification = (event: CaptureEvent<any>, handle?: number)=>void;

class Capture {
  transport: RpcTransport;
  host: string = DEFAULT_HOST;
  clientOrDeviceHandle: number;
  transportHandle: number;
  rpcId: number = 0;
  onEventNotification: Notification;
  rootCapture?: Capture;
  logger?: Logger;

  constructor(log?: Logger) {
    this.logger = log;
  }

  open(appInfo: AppInfo, eventNotification: Notification, options?: CaptureOptions): Promise<number>{
    if (options) {
      this.transport = options.transport || Transport.getTransport(this.logger);
      this.host = options.host || DEFAULT_HOST;
    }
    else {
      // this is done here for transport lazy loading
      this.transport = Transport.getTransport(this.logger);
    }
    return this.transport.open(this.host, (event: JRpcEvent<any>) => {this.notification(event);})
    .then(transportHandle => {
        const jsonRpc = new JRpcRequest<AppInfo>(this.getJsonRpcId(),'openclient', {
          appId: appInfo.appId,
          developerId: appInfo.developerId,
          appKey: appInfo.appKey
        });
        this.onEventNotification = eventNotification;
        this.transportHandle = transportHandle.handle;
        return this.transport.send(transportHandle.handle, jsonRpc);
    })
    .then(response => {
      if (response.result && response.result.handle) {
        this.clientOrDeviceHandle = response.result.handle;
        return SktErrors.ESKT_NOERROR;
      } else {
        const res = response as unknown as JRpcError;
        if (res.error){
          const {error} = res;
          throw (new JRpcError(0, error.code, error.message));
        } else {
          throw ( new JRpcError(0, SktErrors.ESKT_COMMUNICATIONERROR, "There was an error during communication."));
      }
      }
    });
  }

  close(): Promise<number> {
    if (this.transport) {
      const jsonRpc = new JRpcRequest<{handle: number}>(this.getJsonRpcId(), 'close', {
        handle: this.clientOrDeviceHandle
      });
      return this.transport.send(this.transportHandle, jsonRpc)
      .then(() => {
        if (this.rootCapture === undefined) {
          return this.transport.close(this.transportHandle)
          .then(() => {
            this.transport = null;
            this.clientOrDeviceHandle = null;
            this.transportHandle = 0;
            return SktErrors.ESKT_NOERROR;
          });
        }
        this.rootCapture = undefined;
        return SktErrors.ESKT_NOERROR;
      })
    }
    return Promise.reject({error: SktErrors.ESKT_ALREADYDONE});
  }

  openDevice(guid: string, capture: Capture) {
    if(typeof capture === 'undefined' || capture === null) {
      return Promise.reject({error: SktErrors.ESKT_INVALIDPARAMETER});
    }
    this.rootCapture = capture;
    this.transport = capture.transport;
    this.transportHandle = capture.transportHandle;

    if(this.transport) {
      const openRequest = new JRpcRequest<{handle: number, guid: string}>(this.getJsonRpcId(), 'opendevice',{
        handle: this.rootCapture.clientOrDeviceHandle,
        guid
      });
      return this.transport.send(this.transportHandle, openRequest)
      .then((response) => {
        if(response.result && response.result.handle) {
          this.clientOrDeviceHandle = response.result.handle;
          return SktErrors.ESKT_NOERROR;
        } else {
          if (response.error) {
              const { error } = response;
              throw (new JRpcError(0, error.code, error.message));
          }
          else {
              throw (new JRpcError(0, SktErrors.ESKT_COMMUNICATIONERROR, "There was an error during communication."));
          }
        }
      });
    }
    return Promise.reject({error: SktErrors.ESKT_NOTINITIALIZED});
  }

  getProperty<T>(property: CaptureProperty<T>): Promise<CaptureProperty<any>> {
    if (this.transport) {
      return this.transport.send(this.transportHandle, new JRpcRequest(this.getJsonRpcId(), 'getproperty', {
        property, handle: this.clientOrDeviceHandle
      }))
      .then(response => {
        if(response.result){
          if(this.clientOrDeviceHandle != response.result.handle) {
            console.log("Warning the response handle does not match with the handle of the request");
          }
          const propertyResponse = response.result.property as CaptureProperty<unknown>;
          return Promise.resolve(propertyResponse);
        }
        const rsp = response as JRpcError;
        return Promise.reject(rsp.error);
      });
    }
    return Promise.reject(new JRpcError(0, SktErrors.ESKT_NOTINITIALIZED, ERRMSG_NO_TRANSPORT));
  }

  setProperty<T>(property: CaptureProperty<T>): Promise<CaptureProperty<unknown>> {
    if (this.transport) {
      return this.transport.send(this.transportHandle, new JRpcRequest(this.getJsonRpcId(), 'setproperty', {
        property, handle:this.clientOrDeviceHandle
      }))
      .then(response => {
        if(response.result){
          const propertyResponse = response.result.property as CaptureProperty<unknown>;
          return Promise.resolve(propertyResponse);
        }
        const rsp = response as JRpcError;
        return Promise.reject(rsp);
      });
    }
    return Promise.reject(new JRpcError(0, SktErrors.ESKT_NOTINITIALIZED, ERRMSG_NO_TRANSPORT));
  }

  notification(jsonRpc: JRpcEvent<any>, handle?: number): void {
    const result = jsonRpc.result as CaptureEventResult<any>;
    if(jsonRpc && this.onEventNotification){
      this.onEventNotification(result.event, result.handle);
    }
  }

  private getJsonRpcId(): number {
    let self: Capture = this;
    if(this.rootCapture){
      self = this.rootCapture;
    }
    return self.rpcId++;
  }

}

export default Capture;
